<!-- 个人资料 -->
<template>
	<div class="main-container">
		<div class="side-header">
			<div class="name">
				个人资料
			</div>
			<div class="operate" @click="changeEdit">
				{{editVisible ? '保存' : '编辑'}}
			</div>
		</div>
		<div class="main-content" :class="editVisible?'main-edit':'main-normal'">
			<el-form ref="form" class="info-form" :model="form" :rules="rules" label-position="left" label-width="80px">
				<el-form-item label="头像" class="head-item">
					<el-upload
						class="avatar-uploader"
						:action="config.qnuploadurl"
						:show-file-list="false"
						:data="{token: qntoken}"
						:on-success="uploadSuccess" v-if="editVisible">
						<img v-if="form.headImg" :src="form.headImg" class="avatar">
						<i class="el-icon-camera avatar-uploader-icon"></i>
					</el-upload>
					<div v-else>
						<img :src="form.headImg" class="avatar" v-if="form.headImg" alt="">
						<img src="@/assets/images/ip_default_head.jpg" class="avatar" v-else alt="">
					</div>
					
				</el-form-item>
				<el-form-item label="昵称" prop="nickname">
					<el-input v-model="form.nickname" v-if="editVisible"></el-input>
					<div class="txt" v-else-if="form.nickname">{{form.nickname}}</div>
					<div class="txt" v-else>未填写</div>
				</el-form-item>
				<el-form-item label="姓名" prop="name">
					<el-input v-model="form.name" v-if="editVisible"></el-input>
					<div class="txt" v-else-if="form.name">{{form.name}}</div>
					<div class="txt" v-else>未填写</div>
				</el-form-item>
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="form.email" v-if="editVisible"></el-input>
					<div class="txt" v-else-if="form.email">{{form.email}}</div>
					<div class="txt" v-else>未填写</div>
				</el-form-item>
				<el-form-item label="性别">
					<el-radio-group class="gender-radio" v-model="form.gender" v-if="editVisible">
						<el-radio :label="1">男</el-radio>
						<el-radio :label="2">女</el-radio>
					</el-radio-group>
					<div class="txt" v-else>{{genderList[form.gender]}}</div>
				</el-form-item>
				<el-form-item label="生日" prop="birthday">
					<el-date-picker v-model="birthday" type="date" format="yyyy-MM-dd" value-format="timestamp"
						placeholder="选择日期" :picker-options="pickerBeginDateBefore" @change="changeBirthday" v-if="editVisible" />
					<div class="txt" v-else-if="form.birthday">{{form.birthday | stringData}}</div>
					<div class="txt" v-else>未填写</div>
				</el-form-item>
				<el-form-item label="职业" prop="work">
					<el-input v-model="form.work" v-if="editVisible"></el-input>
					<div class="txt" v-else-if="form.work">{{form.work}}</div>
					<div class="txt" v-else>未填写</div>
				</el-form-item>
				<el-form-item label="工作时间" >
					<el-date-picker v-model="workData" type="date" placeholder="选择日期" format="yyyy-MM-dd"
						value-format="timestamp" :picker-options="pickerBeginDateBefore" @change="changeWorkData" v-if="editVisible">
					</el-date-picker>
					<div class="txt" v-else-if="form.workTime">{{form.workTime | stringData}}</div>
					<div class="txt" v-else>未填写</div>
				</el-form-item>
				<el-form-item label="所在城市" prop="city">
					<el-cascader size="large" :options="options" v-model="form.city" @change="changeCity"
						v-if="editVisible">
					</el-cascader>
					<div class="txt" v-else-if="form.provinceName">{{form.provinceName +'/'+ form.cityName +'/'+ form.districtName}}</div>
					<div class="txt" v-else>未填写</div>
				</el-form-item>
				<el-form-item label="院校" prop="colleges">
					<el-input v-model="form.colleges" v-if="editVisible"></el-input>
					<div class="txt" v-else-if="form.colleges">{{form.colleges}}</div>
					<div class="txt" v-else>未填写</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="btn-box" v-if="editVisible">
			<el-button @click="editVisible = false">取消</el-button>
			<el-button type="primary" v-preventReClick @click="onSubmit">保存</el-button>
		</div>
	</div>
</template>

<script>
import config from "@/config";
import { regionData,CodeToText } from "element-china-area-data";
import { idCardValidity,phoneRules,checkEmail,checkURL} from "@/utils/rule.js"
import moment from "moment";
export default {
	filters: {
		stringData: function (date) {
			return moment(date*1000).format("YYYY-MM-DD");
		},
	},
	data() {
		return {
			editVisible: false, // 是否是编辑状态
			qntoken:'',
			config,
			form: {
				headImg: require('@/assets/images/ip_default_head.jpg'),
				nickname: '',
				name: '',
				gender: 1,
				birthday: '',
				work: '',
				workTime: 0,
				provinceId:'',
				provinceName:'',
				cityId:'',
				cityName:'',
				districtId:'',
				districtName:'',
				city: ['', '', ''],
				colleges: ""
			},
			pickerBeginDateBefore: {
				disabledDate(time) {
					let timeSpace = time.getTime() > Date.now();
					return timeSpace;
				}
			},
			birthday:'',
			workData:'',
			genderList:['未选择','男','女'],
			options: regionData,
			rules: {
				nickname: [{
					required: true,
					message: '请输入昵称',
					trigger: 'blur'
				}],
				name: [{
					required: true,
					message: '请输入姓名',
					trigger: 'blur'
				}],
				email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: ['change','blur'] }
        ],
				birthday: [{
					required: true,
					message: '请输入生日',
					trigger: 'blur'
				}],
				work: [{
					required: true,
					message: '请输入职业',
					trigger: 'blur'
				}],
				city: [{
					required: true,
					message: '请输入所在城市',
					trigger: 'blur'
				}],
				colleges: [{
					required: true,
					message: '请输入院校',
					trigger: 'blur'
				}],
			},
		};
	},	
	created(){
		this.getQnToken()
		this.getMyUserInfo()
	},
	methods: {
		getQnToken() {
			this.$store.dispatch("GetUploadToken").then((res) => {
				this.qntoken = res.data.qnToken;
			});
		},
		getMyUserInfo(){
			this.$http
			.get('/api/web/my/getMyUserInfo')
			.then(res=>{
				this.form=res.data.member;
				this.birthday=res.data.member.birthday*1000;
				this.workData=res.data.member.workTime*1000;
				const {provinceId,cityId,districtId}=res.data.member;
				this.form.city=[String(provinceId),String(cityId),String(districtId)];
			})
		},
		uploadSuccess(res){
			this.form.headImg=config.qnurl+res.key;
		},
		changeCity() {
			var loc = "";
			for (let i = 0; i < this.form.city.length; i++) {
				loc += CodeToText[this.form.city[i]];
			}
			this.form.provinceId=this.form.city[0];
			this.form.cityId=this.form.city[1];
			this.form.districtId=this.form.city[2];
		},
		changeBirthday(val){
			this.form.birthday=val/1000;
		},
		changeWorkData(val) {
			this.form.workTime=val/1000;
		},
		changeEdit() {
			if (this.editVisible) {
				this.onSubmit()
			} else {
				this.editVisible = !this.editVisible;
			}
		},
		onSubmit() {
			this.$refs.form.validate((valid) => {
				if (valid) {
					this.$http.post(
						'/api/web/my/postEditMemberInfo',this.form
					)
					.then((res) => {
						this.$toast(res.msg);
						this.$store.commit("SET_USER_INFO", {
							memberHead:this.form.headImg,
							nickname:this.form.nickname
						});
						this.editVisible=false;
					})
					.catch((err) => {
						this.$toast(err.msg)
					});
				} else {
					// this.$toast('请填写完整表单')
					return false;
				}
			});
			
		},
	}
};
</script>

<style lang='less' scoped>
	.main-container {
		background: #fff;

		.side-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 68px;
			padding: 0 20px 0 40px;
			border-bottom: 1px solid #F5F5F5;

			.name {
				font-size: 16px;
				color: #333;
			}

			.operate {
				line-height: 68px;
				padding: 0 20px;
				color: #D71A18;
				cursor: pointer;
			}
		}

		.main-content {
			padding: 0 30px 40px;
			&.main-normal{
				/deep/.el-form-item__label{
					&::before{
						display: none;
					}
				}
			}
			/deep/.info-form {
				padding: 40px 10px 0;
				.head-item{
					height: 90px;
					.el-form-item__label{
						line-height: 90px;
					}
					.avatar-uploader .el-upload {
						position: relative;
						width: 90px;
						height: 90px;
						border: 1px dashed #d9d9d9;
						border-radius: 6px;
						cursor: pointer;
						position: relative;
						overflow: hidden;
						border-radius:50% ;
						background: #00000040;
					}
					.avatar-uploader .el-upload:hover {
						border-color: #409EFF;
					}
					.avatar-uploader-icon {
						position: absolute;
						left: 0;
						top: 0;
						font-size: 28px;
						color: #fff;
						width: 90px;
						height: 90px;
						line-height: 90px;
						text-align: center;
					}
					.avatar{
						width: 90px;
						height: 90px;
						border-radius: 50%;
					}
				}
				.el-form-item {
					width: 550px;
					margin-bottom: 30px;
					
					// display: flex;
					.el-form-item__label {
						position: relative;

						&::before {
							position: absolute;
							top: 2px;
							left: -2px;
						}

						padding-left: 10px;
					}

					.el-form-item__content {
						display: flex;
					}

					.el-cascader,
					.el-input {
						width: 100%;
					}
				}

				.gender-radio {
					display: flex;
					align-items: center;
					height: 40px;
					padding-left: 10px;

					.el-radio__inner {
						width: 18px;
						height: 18px;
					}
				}
			}
			.txt{
				color: #333;
			}
		}
		.btn-box{
			border-top: 1px solid #f5f5f5;
			padding:40px 0 50px;
			.el-button{
				min-width: 180px;
				&:first-child{
					margin-right: 30px;
				}
			}
		}
	}
</style>
